import React, { useState, useEffect, useRef } from 'react';
import '../src/App.css'; // Import the CSS file

const LandingPage = () => {
  const missionRef = useRef(null);
  const contactRef = useRef(null);
  const emailRef = useRef(null);
  const footerRef = useRef(null);

  const [activeSlide, setActiveSlide] = useState(0);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    });
  };

  const navigateToLink = () => {
    window.location.href = 'https://your-website.com';
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((currentSlide) => {
        const nextSlide = (currentSlide + 1) % 3;
        return nextSlide;
      });
    }, 100000); // Change slide every 3 seconds
  
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const checkScroll = () => {
      if (!showScrollButton && window.pageYOffset > window.innerHeight / 2) {
        setShowScrollButton(true);
      } else if (showScrollButton && window.pageYOffset <= window.innerHeight / 2) {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [showScrollButton]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="landing-page">
    <header className="header">
    <div className="landing-page">

    {showScrollButton && (
      <button onClick={scrollToTop} className="scroll-to-top-button">
        ↑
      </button>
    )}
  </div>
      <h1>
        
        <img src="/Phone icon.png" alt="Phone Icon" style={{ verticalAlign: 'middle' }} />
        (954) 266-8828
        <img src="/clock-icon.png" alt="Clock Icon" style={{ verticalAlign: 'middle' }} />
        9-17h every day
        <img src="/Ping point icon.png" alt="Pinpoint Icon" style={{ verticalAlign: 'middle' }} />
         U.S, FL
      </h1>
      
    </header>



      <div className="tab-container">
        
        <div className="tab-header">
        <img src="/logo.png" alt="Pinpoint Icon" className="small-logo" />

      <p1>MJG Insurance Advisors</p1>

          <button onClick={() => scrollToRef(missionRef)}>About</button>
          <button onClick={() => scrollToRef(emailRef)}>Get Quote</button>
          <button onClick={() => scrollToRef(contactRef)}>Contact Us</button>
          <button onClick={navigateToLink}>Sign In</button>
        </div>
        
        <div className="slideshow">

        <button className="slide-nav left-nav" onClick={() => setActiveSlide((activeSlide - 1 + 3) % 3)}>&lt;</button>

        <div className={activeSlide === 0 ? 'slide active' : 'slide'}>
          <img src="/insurance.jpg" alt="First Slide" />
          <button onClick={() => scrollToRef(emailRef)} className="get-quote-button">Get Quote</button>
          <div className="overlay-content">
          <h2>Empowering Your Peace of Mind, MJG Insurance Advisors.</h2>
            <p>Where Expertise Meets Personalized Care. Secure Your Legacy with Unmatched Protection and Service.</p>
          </div>
        </div>

        <div className={activeSlide === 1 ? 'slide active' : 'slide'}>
          <img src="/download (2).jpg" alt="Second Slide" />
          <button onClick={() => scrollToRef(emailRef)} className="get-quote-button">Get Quote</button>
          <div className="overlay-content">
            <h2>Anticipate Tomorrow with Confidence.</h2>
            <p> MJG Insurance Advisors—Your Beacon in a World of Uncertainty.</p>

          </div>
          
        </div>

        <div className={activeSlide === 2 ? 'slide active' : 'slide'}>
          <img src="/2309530.jpg" alt="Third Slide" />
          <button onClick={() => scrollToRef(emailRef)} className="get-quote-button">Get Quote</button>
          <div className="overlay-content">
          <h2>Embrace family moments without worry.</h2>
            <p>MJG Insurance Advisors ensures your future is safeguarded, allowing you to cherish every minute.</p>
          </div>
        </div>

        <button className="slide-nav right-nav" onClick={() => setActiveSlide((activeSlide + 1) % 3)}>&gt;</button>

        </div>





        <div className="tab-content">
          <div ref={missionRef} className="mission-section">
            <MissionStatement />
          </div>
          <div ref={emailRef} className="email-subscriber-section">
            < Plans />
          </div>
          <div ref={contactRef} className="contact-section">
            <ContactUs />
          </div>

          <div ref={footerRef} className="footer-section">
            <Footer />
          </div>
        </div>
      </div>


    </div>
  );
};
const MissionStatement = () => {
  return (
    <div className="mission-container">
      <h2>About Us</h2>
      <p>Here you can see our insurance plans that include individual, group, and family plans.</p>
      
      <div className="mission-content">
        <div className="mission-text">
          <div className="why-us">
            <h1>WHY US?</h1>
            <h3>What we offer to you:</h3>
            <ul>
          <li data-number="1">We conduct tailored evaluations of clients' unique situations to recommend optimal insurance coverage.</li>
          <li data-number="2">Comparing various insurance policies to identify optimal rates and coverage for clients.</li>
          <li data-number="3">Guiding clients through the claims process, we ensure settlements are timely and equitable.</li>
          <li data-number="4">Our advisors offer ongoing support, reviewing and updating clients' policies as their needs evolve.</li>
          <li data-number="5">Delivering expert advice to select the right insurance products for individual,family, or business requirements.</li>
        </ul>
          </div>

        </div>
        
        <div className="mission-image">
          <img src="/ourmission.png" alt="About Us" />

          <div className="best-doctors">
            <h3>The Best Doctors</h3>
            <p>Our advisors maintain connections with top-tier medical professionals, ensuring that clients have access to the best healthcare advice and services when selecting health insurance policies. This network of esteemed doctors enhances the quality and appropriateness of the healthcare coverage we recommend, providing our clients with peace of mind that they are supported by leading experts in the medical field. Through these relationships, our advisors can offer insights into the healthcare landscape, aiding clients in making informed decisions that align with their health and wellness goals.






</p>
          </div>
        </div>
        
        <div className="best-for-you">
          <h3>BEST FOR YOU</h3>
          <p>Leveraging relationships with premier doctors, you gain access to expert healthcare advice, ensuring your insurance choices are aligned with exceptional medical standards for your optimal health and well-being.

          </p>
          <button onClick={() => window.location.href = 'https://your-website.com/openings'}>Learn More</button>

        </div>
      </div>
    </div>
  );
};





const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <div className="blue-background"> Contact US </div> {/* Blue background */}
      <div className="container-content"> {/* Container for actual content */}
        <div className="location-container">
          <div className="text-content">
            <h2>Location</h2>
            <p>
              If you're interested in a position at one of our offices located in South Florida, please click on the button below to access all of our available openings.
            </p>
            <button className="learn-more-button" onClick={() => window.location.href = 'https://your-website.com/openings'}>Learn More</button>
          <img src="/FLL Skyline.png" alt="Location Placeholder" className="location-image" />
          </div>
        </div>
        <form className="contact-form">
          <h2>Contact Us</h2>
          <p>Phone: (954) 266-8828</p>
          <p>Email: recruiting@scionhq.com</p>
          <div>
            <label htmlFor="firstName">First Name:</label>
            <input type="text" id="firstName" name="firstName" />
          </div>
          <div>
            <label htmlFor="lastName">Last Name:</label>
            <input type="text" id="lastName" name="lastName" />
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" />
          </div>
          <div>
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" rows="4" />
          </div>
          <button type="submit" className="send-button">Send</button>
        </form>
      </div>
    </div>
  );
};



const Plans = () => {
  const [sliderValue, setSliderValue] = useState(0); // State to hold the slider value
  const [selectedOption, setSelectedOption] = useState('personal'); // State to hold the selected option

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="email-subscriber-container">
    <h1 className="qu">Insurance quote</h1>

      <div >

        <div className="option-buttons">
          <button onClick={() => handleOptionChange('personal')}>Personal Insurance</button>
          <button onClick={() => handleOptionChange('family')}>Family Insurance</button>
          <button onClick={() => handleOptionChange('group')}>Group Insurance</button>
        </div>
        <div className="image-container">
        {selectedOption === 'personal' && (
          <img src="/personal.jpg" alt="Personal Insurance" />
        )}

        {selectedOption === 'family' && (
          <img src="/family.jpg" alt="Family Insurance" />
        )}

        {selectedOption === 'group' && (
          <img src="/group.jpg" alt="Group Insurance" />
        )}
      </div>

      </div>

      

      <div className="insurance-quote-form">
        <h2>Insurance quote form</h2>
        {selectedOption === 'personal' && (
        <form>
        <div>
          <label htmlFor="QuotefirstName">Your Income:</label>
          <input type="number" id="QuotefirstName" name="QuotefirstName" />
        </div>

        <div>
          <label htmlFor="Quotevalue">Your monthly rate? (0-2000):</label>
          <input 
            type="range" 
            id="Quotevalue" 
            name="Quotevalue" 
            min="0" 
            max="2000" 
            value={sliderValue} 
            onChange={handleSliderChange}
          />
          <span id="valueLabel">{sliderValue}</span>
        </div>

        <div>
          <label htmlFor="QuotefirstName">Your Name:</label>
          <input type="text" id="QuotefirstName" name="QuotefirstName" />
        </div>

        <div>
          <label htmlFor="Quoteemail">Email:</label>
          <input type="email" id="Quoteemail" name="Quoteemail" />
        </div>

        <div>
          <label htmlFor="QuotelastName">Phone:</label>
          <input type="text" id="QuotelastName" name="QuotelastName" />
        </div>

        <div>
          <label htmlFor="Quoteage">Age Range:</label>
          <select id="Quoteage" name="Quoteage">
            <option value="18-25">18-25</option>
            <option value="26-35">26-35</option>
            <option value="36-45">36-45</option>
            <option value="46-55">46-55</option>
            <option value="56-65">56-65</option>
            <option value="66-75">66-75</option>
            <option value="76-85">76-85</option>
            <option value="86-95">86-95</option>
            <option value="95+">95+</option>
          </select>
        </div>

        <div>
          <label htmlFor="Quotemessage">Note:</label>
          <textarea id="Quotemessage" name="Quotemessage" rows="4" />
        </div>

        <button type="submit" className="quote-send-button">Sumbit</button>
        
      </form>
      
        )}

        {selectedOption === 'family' && (
          <form>
        <form>
          <div>
            <label htmlFor="QuotefirstName">Your Income:</label>
            <input type="number" id="QuotefirstName" name="QuotefirstName" />
          </div>

          <div>
            <label htmlFor="Quotevalue">Your monthly rate? (0-2000):</label>
            <input 
              type="range" 
              id="Quotevalue" 
              name="Quotevalue" 
              min="0" 
              max="2000" 
              value={sliderValue} 
              onChange={handleSliderChange}
            />
            <span id="valueLabel">{sliderValue}</span>
          </div>

          <div>
            <label htmlFor="QuotefirstName">Your Name:</label>
            <input type="text" id="QuotefirstName" name="QuotefirstName" />
          </div>

          <div>
            <label htmlFor="Quoteemail">Email:</label>
            <input type="email" id="Quoteemail" name="Quoteemail" />
          </div>

          <div>
            <label htmlFor="QuotelastName">Phone:</label>
            <input type="text" id="QuotelastName" name="QuotelastName" />
          </div>

          <div>
            <label htmlFor="Quoteage">Age Range:</label>
            <select id="Quoteage" name="Quoteage">
              <option value="18-25">18-25</option>
              <option value="26-35">26-35</option>
              <option value="36-45">36-45</option>
              <option value="46-55">46-55</option>
              <option value="56-65">56-65</option>
              <option value="66-75">66-75</option>
              <option value="76-85">76-85</option>
              <option value="86-95">86-95</option>
              <option value="95+">95+</option>
            </select>
          </div>

          <div>
            <label htmlFor="Quotemessage">Note:</label>
            <textarea id="Quotemessage" name="Quotemessage" rows="4" />
          </div>

          <button type="submit" className="quote-send-button">Sumbit</button>
        </form>
          </form>
        )}

        {selectedOption === 'group' && (
          <form>
        <form>
          <div>
            <label htmlFor="QuotefirstName">Your Income:</label>
            <input type="number" id="QuotefirstName" name="QuotefirstName" />
          </div>

          <div>
            <label htmlFor="Quotevalue">Your monthly rate? (0-2000):</label>
            <input 
              type="range" 
              id="Quotevalue" 
              name="Quotevalue" 
              min="0" 
              max="2000" 
              value={sliderValue} 
              onChange={handleSliderChange}
            />
            <span id="valueLabel">{sliderValue}</span>
          </div>

          <div>
            <label htmlFor="QuotefirstName">Your Name:</label>
            <input type="text" id="QuotefirstName" name="QuotefirstName" />
          </div>

          <div>
            <label htmlFor="Quoteemail">Email:</label>
            <input type="email" id="Quoteemail" name="Quoteemail" />
          </div>

          <div>
            <label htmlFor="QuotelastName">Phone:</label>
            <input type="text" id="QuotelastName" name="QuotelastName" />
          </div>

          <div>
            <label htmlFor="Quoteage">Age Range:</label>
            <select id="Quoteage" name="Quoteage">
              <option value="18-25">18-25</option>
              <option value="26-35">26-35</option>
              <option value="36-45">36-45</option>
              <option value="46-55">46-55</option>
              <option value="56-65">56-65</option>
              <option value="66-75">66-75</option>
              <option value="76-85">76-85</option>
              <option value="86-95">86-95</option>
              <option value="95+">95+</option>
            </select>
          </div>

          <div>
            <label htmlFor="Quotemessage">Note:</label>
            <textarea id="Quotemessage" name="Quotemessage" rows="4" />
          </div>

          <button type="submit" className="quote-send-button">Sumbit</button>
        </form>
          </form>
        )}
      </div>
    </div>
  );
};


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 by ScionHQ. All rights reserved.</p>
        <p>recruiting@scionhq.com | (954) 266-8828</p>
      </div>
    </footer>
  );
};


export default LandingPage;
